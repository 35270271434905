import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import SimplexNoise from 'simplex-noise';
import * as S from './styles';

const ReactAnimation = () => {
  const mount = useRef(null);
  let Ow = 0; // Example value, replace with actual usage
  let frameId;
  const simplex = new SimplexNoise();

  useEffect(() => {
    const canvas = mount.current;
    const renderer = new THREE.WebGLRenderer({
      canvas,
      antialias: true,
      alpha: true,
    });
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    camera.position.set(0, 0, 2.5);

    const geometry = new THREE.SphereGeometry(1, 128, 128);
    const material = new THREE.MeshStandardMaterial({ color: 0xc9ebe5 });
    const sphere = new THREE.Mesh(geometry, material);
    scene.add(sphere);

    const lightTop = new THREE.PointLight(0xc9ebe5, 1);
    lightTop.position.set(0, 500, 300);
    scene.add(lightTop);

    const lightBottom = new THREE.PointLight(0xc9ebe5, 1);
    lightBottom.position.set(0, 600, 100);
    scene.add(lightBottom);

    const update = () => {
      const time = performance.now() * 3e-5 * 1 * Math.pow(1.3, 5);
      const spikes = 0.6 * 1.3;
      camera.position.z = 2.5 - Ow;

      for (let i = 0; i < sphere.geometry.vertices.length; i++) {
        const vertex = sphere.geometry.vertices[i];
        vertex
          .normalize()
          .multiplyScalar(
            1 +
              0.3 *
                simplex.noise3D(
                  vertex.x * spikes,
                  vertex.y * spikes,
                  vertex.z * spikes + time
                )
          );
      }

      sphere.geometry.computeVertexNormals();
      sphere.geometry.normalsNeedUpdate = true;
      sphere.geometry.verticesNeedUpdate = true;

      renderer.render(scene, camera);

      frameId = requestAnimationFrame(update);
    };

    const resizeHandler = () => {
      const width = canvas.clientWidth;
      const height = canvas.clientHeight;

      camera.aspect = width / height;
      camera.updateProjectionMatrix();

      renderer.setSize(width, height);
    };

    window.addEventListener('resize', resizeHandler);

    update();

    return () => {
      cancelAnimationFrame(frameId);
      window.removeEventListener('resize', resizeHandler);
      renderer.dispose();
      geometry.dispose();
      material.dispose();
    };
  }, []);

  return (
    <S.BgAnimated>
      <div
        data-scroll-section=''
        data-scroll-section-id='section0'
        data-scroll-section-inview=''
        style={{
          transform: 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
          opacity: 1,
          pointerEvents: 'all',
        }}>
        <section
          id='slideshow'
          parallax='.1'
          opacity='.1'
          data-scroll-sticky=''
          className='slideshow'>
          <div className='slideshow-inner header_animate active'>
            <div className='slides'>
              <div className='slide q_current scrollanim_fade_container is-loaded'>
                <div className='image-container'>
                  <div
                    className='image-wrapper reveal_short'
                    style={{ opacity: 1 }}>
                    <div
                      data-scroll=''
                      data-scroll-speed={-7}
                      data-scroll-repeat=''
                      className='is-inview'
                      data-scroll-offset={0}
                      style={{
                        transform:
                          'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -28.7, 0, 1)',
                      }}>
                      <div
                        id='blob'
                        className='blob scrollanim_fade'
                        style={{ opacity: 1 }}>
                        <canvas
                          className='active slide_active'
                          width={1788}
                          height={1812}
                          ref={mount}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </S.BgAnimated>
  );
};

export default ReactAnimation;
