const Calculator = ({ ...props }) => {
  return (
    <svg
      viewBox='-0.5 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      stroke='#fff'
      {...props}>
      <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        stroke-linecap='round'
        stroke-linejoin='round'></g>
      <g id='SVGRepo_iconCarrier'>
        {' '}
        <path
          d='M19.505 21.475C19.505 21.765 19.185 21.995 18.785 21.995H5.215C4.825 21.995 4.495 21.765 4.495 21.475V3.525C4.495 3.235 4.815 3.005 5.215 3.005H18.785C19.175 3.005 19.505 3.235 19.505 3.525V21.475Z'
          stroke='#fff'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'></path>{' '}
        <path
          d='M16.395 8.505H7.61499C7.27499 8.505 7.005 8.235 7.005 7.895V6.11501C7.005 5.77501 7.27499 5.505 7.61499 5.505H16.395C16.735 5.505 17.005 5.77501 17.005 6.11501V7.895C17.005 8.235 16.725 8.505 16.395 8.505Z'
          stroke='#fff'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'></path>{' '}
        <path
          d='M7.005 10.505H9.005'
          stroke='#fff'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'></path>{' '}
        <path
          d='M16.005 10.505H17.005'
          stroke='#fff'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'></path>{' '}
        <path
          d='M7.005 13.505H9.005'
          stroke='#fff'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'></path>{' '}
        <path
          d='M11.005 13.505H13.005'
          stroke='#fff'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'></path>{' '}
        <path
          d='M15.005 13.505H17.005'
          stroke='#fff'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'></path>{' '}
        <path
          d='M7.005 15.505H9.005'
          stroke='#fff'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'></path>{' '}
        <path
          d='M11.005 15.505H13.005'
          stroke='#fff'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'></path>{' '}
        <path
          d='M15.005 15.505H17.005'
          stroke='#fff'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'></path>{' '}
        <path
          d='M7.005 17.505H9.005'
          stroke='#fff'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'></path>{' '}
        <path
          d='M11.005 17.505H13.005'
          stroke='#fff'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'></path>{' '}
        <path
          d='M15.005 17.505H17.005'
          stroke='#fff'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'></path>{' '}
        <path
          d='M7.005 19.505H9.005'
          stroke='#fff'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'></path>{' '}
        <path
          d='M11.005 19.505H13.005'
          stroke='#fff'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'></path>{' '}
        <path
          d='M15.005 19.505H17.005'
          stroke='#fff'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'></path>{' '}
      </g>
    </svg>
  );
};

export default Calculator;
