export const products = [
  {
    category_name: 'Slate',
    path: '/slate',
    products: 13,
    price_small: 768,
    price_big: 999,
    options: ['1200 x 600', '2400 x 1200'],
    variety: [
      {
        name: 'Slate-043 / H06',
        img: '1.jpg',
      },
      {
        name: 'Blue Black Slate',
        img: '2.jpg',
      },
      {
        name: 'Slate 052 / HY001',
        img: '3.jpg',
      },
      {
        name: 'Slate 221 / HY02',
        img: '4.jpg',
      },
      {
        name: 'Yellow Slate',
        img: '5.jpg',
      },
      {
        name: 'Clay Red Slate / 058',
        img: '6.jpg',
      },
      {
        name: 'Slate Arte / HY03-04-05',
        img: '7.jpg',
      },
      {
        name: 'Slate Phantom / 203206',
        img: '8.jpg',
      },
      {
        name: 'Slate Sunny / Y02-03-04',
        img: '9.jpg',
      },
      {
        name: 'White Slate / H001 / 050',
        img: '10.jpg',
      },
      {
        name: 'Clay Yellow Slate',
        img: '11.jpg',
      },
      {
        name: 'Clay Yellow Slate',
        img: '12.jpg',
      },
    ],
  },
  {
    category_name: 'Mount Celestial',
    path: '/mount_celestial',
    products: 13,
    price_small: 810,
    price_big: 1039,
    options: ['1200 x 600', '2400 x 1200'],
    variety: [
      {
        name: 'F02',
        img: '1.jpg',
      },
      {
        name: 'H02',
        img: '2.jpg',
      },
      {
        name: 'H05',
        img: '3.jpg',
      },
      {
        name: 'HB02',
        img: '4.jpg',
      },
      {
        name: 'HB03',
        img: '5.jpg',
      },
      {
        name: 'HY01',
        img: '6.jpg',
      },
      {
        name: 'HG02',
        img: '7.jpg',
      },
      {
        name: 'HG03',
        img: '8.jpg',
      },
      {
        name: 'HY001',
        img: '9.jpg',
      },
      {
        name: 'O01',
        img: '10.jpg',
      },
      {
        name: 'H01-02-03',
        img: '11.jpg',
      },
      {
        name: 'H04-05-06',
        img: '12.jpg',
      },
      {
        name: 'HG01-02-03',
        img: '13.jpg',
      },
      {
        name: 'Y001-01-02',
        img: '14.jpg',
      },
      {
        name: 'Portoro',
        img: '15.jpg',
      },
      {
        name: 'Castol White',
        img: '16.jpg',
      },
      {
        name: 'Castol Yellow',
        img: '17.jpg',
      },
      {
        name: 'Castol Grey',
        img: '18.jpg',
      },
      {
        name: 'Castol Blue',
        img: '19.jpg',
      },
      {
        name: 'Lion Dark Brown',
        img: '20.jpg',
      },
      {
        name: 'Lion Cool Grey',
        img: '21.jpg',
      },
      {
        name: 'Lion Warm Grey',
        img: '22.jpg',
      },
      {
        name: 'Veil White',
        img: '23.jpg',
      },
      {
        name: 'Veil Grey',
        img: '24.jpg',
      },
    ],
  },
  {
    category_name: 'Traverine',
    path: '/travertine',
    products: 13,
    price_small: 827,
    price_big: 1039,
    options: ['1200 x 600', '2400 x 1200'],
    variety: [
      {
        name: '031Y / H05Y',
        img: '1.jpg',
      },
      {
        name: '031YZD / H05YZD',
        img: '2.jpg',
      },
      {
        name: '050 / H001',
        img: '3.jpg',
      },
      {
        name: '052 / HY001',
        img: '4.jpg',
      },
      {
        name: '052K / HY001 K',
        img: '5.jpg',
      },
      {
        name: '056 / О 03',
        img: '6.jpg',
      },
      {
        name: 'Gold',
        img: '7.jpg',
      },
      {
        name: 'Grey',
        img: '8.jpg',
      },
      {
        name: 'Light Brown',
        img: '9.jpg',
      },
      {
        name: 'Red',
        img: '10.jpg',
      },
      {
        name: 'White',
        img: '11.jpg',
      },
      {
        name: 'Yellow',
        img: '12.jpg',
      },
      {
        name: 'Grey',
        img: '13.jpg',
      },
      {
        name: 'Orange',
        img: '14.jpg',
      },
      {
        name: 'White',
        img: '15.jpg',
      },
      {
        name: 'Yellow',
        img: '16.jpg',
      },
    ],
  },
  {
    category_name: 'Marble',
    path: '/marble',
    products: 13,
    price_small: 827,
    price_big: 1039,
    options: ['1200 x 600', '2400 x 1200'],
    variety: [
      {
        name: 'Spainish White',
        img: '1.jpg',
      },
      {
        name: 'Spainish Beige',
        img: '2.jpg',
      },
      {
        name: 'Spainish Light Grey',
        img: '3.jpg',
      },
      {
        name: 'Spainish Light Yellow',
        img: '4.jpg',
      },
      {
        name: 'Castol White',
        img: '5.jpg',
      },
      {
        name: 'Castol Yellow',
        img: '6.jpg',
      },
      {
        name: 'Castol Grey',
        img: '7.jpg',
      },
      {
        name: 'Castol Blue',
        img: '8.jpg',
      },
      {
        name: 'Veil White',
        img: '9.jpg',
      },
      {
        name: 'Veil Yellow',
        img: '10.jpg',
      },
      {
        name: 'Veil Dark Brown',
        img: '11.jpg',
      },
      {
        name: 'Veil Gold',
        img: '12.jpg',
      },
      {
        name: 'Andes White',
        img: '13.jpg',
      },
      {
        name: 'Andes Yellow',
        img: '14.jpg',
      },
      {
        name: 'Andes Light Brown',
        img: '15.jpg',
      },
      {
        name: 'Andes Gold',
        img: '16.jpg',
      },
      {
        name: 'Cloud White',
        img: '17.jpg',
      },
      {
        name: 'Cloud Yellow',
        img: '18.jpg',
      },
      {
        name: 'Cloud Grey',
        img: '19.jpg',
      },
      {
        name: 'Cloud Orange',
        img: '20.jpg',
      },
    ],
  },
  {
    category_name: 'Polish Concrete',
    path: '/polish_concrete',
    products: 13,
    price_small: 827,
    price_big: 1039,
    options: ['1200 x 600', '2400 x 1200'],
    variety: [
      {
        name: 'Wall H04-05-06',
        img: '1.jpg',
      },
      {
        name: 'Wall HB02-03-04',
        img: '2.jpg',
      },
      {
        name: 'Wall HB02-03-04',
        img: '3.jpg',
      },
      {
        name: 'Wall HY001-01-02',
        img: '4.jpg',
      },
      {
        name: 'Wall O001-01-02',
        img: '5.jpg',
      },
      {
        name: 'Wall O04-05-06',
        img: '6.jpg',
      },
      {
        name: 'Wall Great Desert',
        img: '7.jpg',
      },
      {
        name: 'Wall Greyish Desert',
        img: '8.jpg',
      },
      {
        name: 'Wall Cloud',
        img: '9.jpg',
      },
      {
        name: 'Wall Dark Brown',
        img: '10.jpg',
      },
      {
        name: 'Wall Medium Grey',
        img: '11.jpg',
      },
    ],
  },
  {
    category_name: 'Granite',
    path: '/granite',
    products: 13,
    price_small: 910,
    price_big: 1080,
    options: ['1200 x 600', '2400 x 1200'],
    variety: [
      {
        name: 'White Weave',
        img: '1.jpg',
      },
      {
        name: 'Dusk Grey',
        img: '2.jpg',
      },
      {
        name: 'Gold',
        img: '3.jpg',
      },
      {
        name: 'Coffee',
        img: '4.jpg',
      },
      {
        name: 'Nanshan',
        img: '5.jpg',
      },
      {
        name: 'Tianshan Red',
        img: '6.jpg',
      },
      {
        name: 'Biege White',
        img: '7.jpg',
      },
      {
        name: 'Middle Grey',
        img: '8.jpg',
      },
      {
        name: '025',
        img: '9.jpg',
      },
      {
        name: 'Black Blue',
        img: '10.jpg',
      },
      {
        name: '038',
        img: '11.jpg',
      },
      {
        name: '043',
        img: '12.jpg',
      },
      {
        name: '367',
        img: '13.jpg',
      },
      {
        name: 'Red Ink',
        img: '14.jpg',
      },
      {
        name: 'Yanshan Red',
        img: '15.jpg',
      },
      {
        name: 'Black',
        img: '16.jpg',
      },
    ],
  },
  {
    category_name: 'Rough Surface',
    path: '/rough_surface',
    products: 13,
    price_small: 910,
    price_big: 1080,
    options: ['1200 x 600', '2400 x 1200'],
    variety: [
      {
        name: 'F02',
        img: '1.jpg',
      },
      {
        name: 'H02',
        img: '2.jpg',
      },
      {
        name: 'HB02',
        img: '3.jpg',
      },
      {
        name: 'HB03',
        img: '4.jpg',
      },
      {
        name: 'HG03',
        img: '5.jpg',
      },
      {
        name: 'HY001',
        img: '6.jpg',
      },
      {
        name: 'Y01',
        img: '7.jpg',
      },
      {
        name: 'Veil White',
        img: '8.jpg',
      },
      {
        name: 'Veil Grey',
        img: '9.jpg',
      },
      {
        name: 'Veil Gold',
        img: '10.jpg',
      },
      {
        name: 'Veil Dark Grey',
        img: '11.jpg',
      },
      {
        name: 'Ash Grey',
        img: '12.jpg',
      },
    ],
  },
  {
    category_name: 'Limestone',
    path: '/limestone',
    products: 13,
    price_small: 910,
    price_big: 1080,
    options: ['1200 x 600', '2400 x 1200'],
    variety: [
      {
        name: 'Z 001',
        img: '1.jpg',
      },
      {
        name: 'Y 01',
        img: '2.jpg',
      },
      {
        name: 'HY 01',
        img: '3.jpg',
      },
      {
        name: 'R 01',
        img: '4.jpg',
      },
      {
        name: 'H 02',
        img: '5.jpg',
      },
      {
        name: 'O 01',
        img: '6.jpg',
      },
      {
        name: 'Nile Warm Grey',
        img: '7.jpg',
      },
      {
        name: 'Nile Light Yellow',
        img: '8.jpg',
      },
      {
        name: 'Nile Medium Yellow',
        img: '9.jpg',
      },
      {
        name: 'Nile Dark Grey',
        img: '10.jpg',
      },
    ],
  },
  {
    category_name: 'Chiseled Stone',
    path: '/chiseled_stone',
    products: 13,
    price_small: 910,
    price_big: 1120,
    options: ['1200 x 600', '2400 x 1200'],
    variety: [
      {
        name: 'Andes White',
        img: '1.jpg',
      },
      {
        name: 'Andes Yellow',
        img: '2.jpg',
      },
      {
        name: 'H001',
        img: '3.jpg',
      },
      {
        name: 'Andes Light Brown',
        img: '4.jpg',
      },
      {
        name: 'Andes Light Brown',
        img: '5.jpg',
      },
      {
        name: 'Cloud Yellow',
        img: '6.jpg',
      },
      {
        name: 'Spainish Yellow',
        img: '7.jpg',
      },
      {
        name: 'Spainish Light Brown',
        img: '8.jpg',
      },
      {
        name: 'F02',
        img: '9.jpg',
      },
      {
        name: 'HB04',
        img: '10.jpg',
      },
      {
        name: 'HG03',
        img: '11.jpg',
      },
    ],
  },
  {
    category_name: 'Rammed Earth Wall',
    path: '/rammed_earth_wall',
    products: 13,
    price_small: 910,
    price_big: 1120,
    options: ['1200 x 600', '2400 x 1200'],
    variety: [
      {
        name: 'Y001',
        img: '1.jpg',
      },
      {
        name: 'Y02',
        img: '2.jpg',
      },
      {
        name: 'Red',
        img: '3.jpg',
      },
      {
        name: 'Yellow',
        img: '4.jpg',
      },
      {
        name: 'Yellow Grey',
        img: '5.jpg',
      },
      {
        name: 'Light Grey',
        img: '6.jpg',
      },
      {
        name: 'Medium Grey',
        img: '7.jpg',
      },
    ],
  },
  {
    category_name: 'Cut Stone',
    path: '/cut_stone',
    products: 13,
    price_small: 910,
    price_big: 1120,
    options: ['1200 x 600'],
    variety: [
      {
        name: '052 / HY001',
        img: '1.jpg',
      },
      {
        name: '221 / H001',
        img: '2.jpg',
      },
      {
        name: '362 / R04',
        img: '3.jpg',
      },
      {
        name: '369 / R02',
        img: '4.jpg',
      },
    ],
  },
  {
    category_name: 'Infiniti',
    path: '/infiniti',
    products: 13,
    price_small: 830,
    price_big: 910,
    options: ['600 x 300', '1200 x 600'],
    variety: [
      {
        name: '058 / О 04',
        img: '1.jpg',
      },
      {
        name: '052 / HY001',
        img: '2.jpg',
      },
      {
        name: '043 / Н06',
        img: '3.jpg',
      },
      {
        name: '031 / Н05',
        img: '4.jpg',
      },
      {
        name: 'H02',
        img: '5.jpg',
      },
      {
        name: 'H03',
        img: '6.jpg',
      },
      {
        name: 'HB02',
        img: '7.jpg',
      },
      {
        name: 'HG03',
        img: '8.jpg',
      },
      {
        name: 'HY002',
        img: '9.jpg',
      },
      {
        name: 'HY03',
        img: '10.jpg',
      },
      {
        name: 'O01',
        img: '11.jpg',
      },
      {
        name: 'Z03',
        img: '12.jpg',
      },
      {
        name: 'White Sesame',
        img: '13.jpg',
      },
      {
        name: 'Greyish Brown',
        img: '14.jpg',
      },
    ],
  },
  {
    category_name: 'Brick',
    path: '/brick',
    products: 13,
    price_small: 830,
    price_big: 910,
    options: ['600 x 300', '1200 x 600'],
    variety: [
      {
        name: '315',
        img: '1.jpg',
      },
      {
        name: '052 / HY001',
        img: '2.jpg',
      },
      {
        name: '043 / H06',
        img: '3.jpg',
      },
      {
        name: '031 / H05',
        img: '4.jpg',
      },
      {
        name: 'F02',
        img: '5.jpg',
      },
      {
        name: 'H01',
        img: '6.jpg',
      },
      {
        name: 'H03',
        img: '7.jpg',
      },
      {
        name: 'HG001',
        img: '8.jpg',
      },
      {
        name: 'HG03',
        img: '9.jpg',
      },
      {
        name: 'Y01',
        img: '10.jpg',
      },
      {
        name: 'Ash Grey',
        img: '11.jpg',
      },
      {
        name: 'Gold Sesame',
        img: '12.jpg',
      },
    ],
  },
  {
    category_name: 'Original Wood',
    path: '/original_wood',
    products: 13,
    price_small: 720,
    price_big: 910,
    options: ['900 x 180', '900 x 110'],
    variety: [
      {
        name: 'Autumn',
        img: '1.jpg',
      },
      {
        name: 'Brownish Yellow',
        img: '2.jpg',
      },
      {
        name: 'Caesious',
        img: '3.jpg',
      },
      {
        name: 'Dark Brown',
        img: '4.jpg',
      },
      {
        name: 'Light Brown',
        img: '5.jpg',
      },
      {
        name: 'Moonlight',
        img: '6.jpg',
      },
    ],
  },
  {
    category_name: 'Classic Wood',
    path: '/classic_wood',
    products: 13,
    price_small: 720,
    price_big: 910,
    options: ['900 x 180', '900 x 110'],
    variety: [
      {
        name: 'Volga',
        img: '1.jpg',
      },
      {
        name: 'Suez',
        img: '2.jpg',
      },
      {
        name: 'Rhine',
        img: '3.jpg',
      },
      {
        name: 'Nile',
        img: '4.jpg',
      },
      {
        name: 'Andes',
        img: '5.jpg',
      },
    ],
  },
  {
    category_name: 'Brick Variety',
    path: '/brick_variety',
    price_small: 720,
    price_big: 910,
    options: ['240 x 120', '480 x 60'],
    variety: [
      { name: 'British blue', img: '1.jpg' },
      { name: 'Вritish grey', img: '2.jpg' },
      { name: 'Вritish red', img: '3.jpg' },
      { name: 'British Yellow', img: '4.jpg' },
      { name: 'Knight brown', img: '5.jpg' },
      { name: 'Knight Cyan', img: '6.jpg' },
      { name: 'Knight grey', img: '7.jpg' },
      { name: 'Knight red', img: '8.jpg' },
      { name: 'Idyllic dark brown', img: '9.jpg' },
      { name: 'Idyllic burgundy', img: '10.jpg' },
      { name: 'Idyllic grey', img: '11.jpg' },
      { name: 'Idyllic grey', img: '12.jpg' },
      { name: 'Idyllic greyish yellow', img: '13.jpg' },
      { name: 'Idyllic red', img: '14.jpg' },
      { name: 'Idyllic white', img: '15.jpg' },
      { name: 'Idyllic yellow', img: '16.jpg' },
      { name: 'Idyllic orange', img: '17.jpg' },
      { name: 'Idyllic brown', img: '18.jpg' },
      { name: 'Idyllic dark grey', img: '19.jpg' },
      { name: 'Idyllic medium grey', img: '20.jpg' },
      { name: 'Idyllic red', img: '21.jpg' },
      { name: 'Misted cyan', img: '22.jpg' },
      { name: 'Misted grey', img: '23.jpg' },
      { name: 'Misted white', img: '24.jpg' },
      { name: 'Os orange', img: '25.jpg' },
      { name: 'Os red', img: '26.jpg' },
      { name: 'Snowy grey', img: '27.jpg' },
      { name: 'Snowy brown', img: '28.jpg' },
      { name: 'Snowy silver', img: '29.jpg' },
      { name: 'Snowy white', img: '30.jpg' },
      { name: 'Iced yellow', img: '31.jpg' },
      { name: 'Iced blue', img: '32.jpg' },
      { name: 'Iced brown', img: '33.jpg' },
      { name: 'Iced grey', img: '34.jpg' },
      { name: 'Inks brown', img: '35.jpg' },
      { name: 'Inks dark brown', img: '36.jpg' },
      { name: 'Inks grey', img: '37.jpg' },
      { name: 'Inks red', img: '38.jpg' },
      { name: 'Inks yellow', img: '39.jpg' },
      { name: 'Jade yellow', img: '40.jpg' },
      { name: 'Kata orange', img: '41.jpg' },
      { name: 'Kata silver', img: '42.jpg' },
      { name: 'Kata white', img: '43.jpg' },
      { name: 'Kata yellow', img: '44.jpg' },
      { name: 'Misted athens yellow', img: '45.jpg' },
      { name: 'Misted grey', img: '46.jpg' },
      { name: 'Misted juzz rust', img: '47.jpg' },
      { name: 'Palace brown blue', img: '48.jpg' },
      { name: 'Palace grey', img: '49.jpg' },
      { name: 'Palace orange green', img: '50.jpg' },
      { name: 'Sudan grey', img: '51.jpg' },
      { name: 'Sudan orange', img: '52.jpg' },
      { name: 'Sudan red', img: '53.jpg' },
      { name: 'Sudan yellow', img: '54.jpg' },
      { name: 'Multi color grey', img: '55.jpg' },
      { name: 'Nile grey', img: '56.jpg' },
      { name: 'Idliic dark grey', img: '57.jpg' },
      { name: 'Idliic grey', img: '58.jpg' },
      { name: 'Idliic ligh yellow', img: '59.jpg' },
      { name: 'Idliic light orange', img: '60.jpg' },
      { name: 'Moonlight brown', img: '61.jpg' },
      { name: 'Moonlight grey', img: '62.jpg' },
      { name: 'Moonlight orange', img: '63.jpg' },
      { name: 'Moonlight silver', img: '64.jpg' },
      { name: 'Multi color brown', img: '65.jpg' },
      { name: 'Multi color cyan', img: '66.jpg' },
      { name: 'Multi color red', img: '67.jpg' },
      { name: 'Nile cyan', img: '68.jpg' },
      { name: 'Nile dark brown', img: '69.jpg' },
      { name: 'Nile red', img: '70.jpg' },
      { name: 'Nile rust', img: '71.jpg' },
      { name: 'Nile yellow', img: '72.jpg' },
      { name: 'Autumn', img: '75.jpg' },
      { name: 'Dark brown', img: '76.jpg' },
      { name: 'Riemann', img: '77.jpg' },
      { name: 'Yloi orange', img: '78.jpg' },
      { name: 'Yoli orange fied', img: '79.jpg' },
      { name: 'Yoli yellow', img: '80.jpg' },
    ],
  },
];
