import styled from 'styled-components';

export const Footer = styled.footer`
  background-image: url(/bg.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  padding: 45px 0 0;
  & > div {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: 1220px) {
      padding: 0 15px;
      flex-wrap: wrap;
    }
    & > div {
      max-width: 30%;
      @media (max-width: 768px) {
        &:first-of-type {
          max-width: 100%;
          width: 100%;
          margin-bottom: 24px;
        }
        &:nth-of-type(2),
        &:nth-of-type(3) {
          max-width: 49%;
          width: 100%;
          margin-bottom: 16px;
        }
        &:nth-of-type(4) {
          max-width: 100%;
          width: 100%;
          margin-bottom: 16px;
        }
      }
      p {
        font-size: 14px;
        color: #3e3935;
        &.line {
          margin-bottom: 4px;
          font-size: 16px;
          position: relative;
          padding-left: 6px;
          transition: 0.3s ease-in-out;
          cursor: pointer;
          transition: 0.3s ease-in-out;
          &:hover {
            color: #212121;
            transform: scale(1.05);
          }
          &::after {
            content: '';
            width: 5px;
            height: 5px;
            background: #1bb9a3;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: -5px;
            transform: translate(0, -50%);
          }
          &:first-of-type {
            margin-top: 12px;
          }
        }
      }
      & > img {
        max-width: 150px;
        margin-bottom: 20px;
        margin-top: -20px;
        cursor: pointer;
      }
      &.column {
        display: flex;
        flex-direction: column;
        p {
          font-weight: 500;
          font-size: 16px;
          margin-bottom: 12px;
          color: #3e3935;
          transition: 0.3s ease-in-out;
          &:hover {
            color: rgb(87, 87, 87);
          }
        }
        a {
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 4px;
          color: #3e3935;
          cursor: pointer;
          &:hover {
            color: #000;
          }
        }
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 8px;
    font-size: 14px;
    width: 100%;
    border-top: 1px solid #ccc;
    padding-top: 8px;
    margin-top: 16px;
  }
`;
