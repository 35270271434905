import styled from 'styled-components';

export const Contact = styled.div`
  max-width: 1170px;
  margin: 64px auto;
  scroll-margin-top: 130px;
  @media (max-width: 1220px) {
    padding: 0 15px;
    margin-top: 48px;
  }
  @media (max-width: 768px) {
    scroll-margin-top: 70px;
  }
  & > h2 {
    font-size: 38px;
    line-height: 42px;
    margin-bottom: 16px;
    font-weight: 600;
    color: blue;
    /* Create a linear gradient from black to blue */
    background: linear-gradient(to right, #212121, #1bb9a4);
    /* Clip the background so it is only behind the text */
    -webkit-background-clip: text;
    background-clip: text;
    /* Make the text color transparent, so the gradient is visible */
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    position: relative;
    width: fit-content;
    text-align: center;
    margin: 0 auto 16px;
    &::after {
      position: absolute;
      width: 100px;
      height: 3px;
      content: '';
      bottom: -7px;
      background: linear-gradient(to right, #212121, #1bb9a4);
      left: 50%;
      transform: translate(-50%, 0);
    }
    @media (max-width: 768px) {
      font-size: 35px;
    }
  }
  & > p {
    font-size: 20px;
    text-align: center;
    margin-bottom: 48px;
    color: rgb(87, 87, 87);
    @media (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 24px;
      br {
        display: none;
      }
    }
  }
  & > div {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 100%;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .left {
      background: #c0584b;
      padding: 40px 40px 20px 40px;
      border-radius: 10px;
      max-width: 36%;
      width: 100%;
      position: relative;
      overflow: hidden;
      height: auto;
      transition: 0.3s ease-in-out;
      @media (max-width: 768px) {
        max-width: 100%;
        padding: 20px;
      }
      .abs {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 0;
      }
      h3 {
        font-size: 24px;
        font-weight: 500;
        color: #fff;
      }
      & > p {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 24px;
        color: #fff;
        @media (max-width: 768px) {
          margin-bottom: 24px;
        }
      }
      .block_individual {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 24px;
        transition: 0.3s ease-in-out;
        @media (max-width: 768px) {
          margin-bottom: 24px;
          z-index: 3;
          position: relative;
        }
        &:hover {
          opacity: 0.6;
          cursor: pointer;
        }
        svg {
          max-width: 25px;
          width: 100%;
        }
        p {
          font-size: 20px;
          color: #fff;
          position: relative;
        }
      }
      iframe {
        border-radius: 6px;
        margin-top: 8px;
        width: 100%;
        z-index: 2;
        position: relative;
      }
      .icons {
        display: flex;
        align-items: center;
        margin-top: 16px;
        gap: 12px;
        margin-top: 30px;
        position: relative;
        z-index: 3;
        justify-content: center;
        @media (max-width: 768px) {
          margin-top: 32px;
        }
        & > div {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.3s ease-in-out;
          &:hover {
            transform: scale(1.1);
            cursor: pointer;
            svg {
              opacity: 1;
            }
          }
        }
        svg {
          max-width: 30px;
          width: 100%;
          height: auto;
          opacity: 0.6;
          transition: 0.3s ease-in-out;
        }
      }
    }
    .right {
      padding: 20px 40px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 32px 32px;
      height: fit-content;
      @media (max-width: 768px) {
        padding: 20px 10px 10px;
      }
      .success {
        padding: 12px 16px;
        border-radius: 8px;
        background: #1bb9a4;
        color: #fff;
        font-size: 14px;
        padding-right: 60px;
        position: relative;
        margin-left: auto;
        margin-top: -20px;
        @media (max-width: 768px) {
          font-size: 12px;
          padding-right: 40px;
        }
        svg {
          position: absolute;
          right: 16px;
          top: 50%;
          transform: translate(0, -50%);
          max-width: 25px;
        }
      }
      .red_border {
        p {
          color: #cf5b4c !important;
        }
        textarea,
        input {
          border-color: #cf5b4c !important;
        }
      }
      .content {
        width: 100%;
        h3 {
          font-weight: 400;
          font-size: 16px;
        }
        .produs {
          width: 100%;
          padding: 5px;
          border: 1px dashed #1bb9a4;
          border-radius: 6px;
          margin-top: 6px;
          margin-bottom: 20px;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          transition: 0.3s ease-in-out;
          display: flex;
          align-items: center;
          gap: 24px;
          position: relative;
          svg {
            max-width: 26px;
            position: absolute;
            top: 0px;
            right: 4px;
            cursor: pointer;
          }
          img {
            max-width: 200px;
            height: 60px;
            border-radius: 4px;
          }
          span {
            font-size: 20px;
            font-weight: 400;
            color: rgb(62, 57, 53);
          }
          &:hover {
            box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;
            cursor: pointer;
          }
        }
        & > div > p,
        & > span,
        & > p {
          margin-top: 8px;
          width: 100%;
          max-width: 100%;
          display: block;
          padding: 12px;
          border-radius: 10px;
          border: 1px dashed #a4dbf4;
          font-size: 12px;
          color: rgb(87, 87, 87);
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          transition: 0.3s ease-in-out;
          &:hover {
            box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;
            cursor: pointer;
          }
        }
      }
      button {
        margin-left: auto;
        padding: 16px 32px;
        border: none;
        border-radius: 8px;
        background: #c0584b;
        color: #fff;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        margin-top: auto;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        &:hover {
          background: #943f34;
        }
      }
      .input {
        max-width: calc(50% - 16px);
        width: 100%;
        p {
          color: #212121;
          font-size: 14px;
        }
        input,
        textarea {
          width: 100%;
          border: none;
          border-bottom: 1px solid #ccc;
          transition: 0.3s ease-in-out;
          padding-bottom: 8px;
          padding-top: 12px;
          resize: none;
          font-family: inherit;
          &:hover,
          &:active,
          &:focus-visible,
          &:focus-within {
            border: none;
            outline: none;
            border-bottom: 1px solid #000;
          }
        }
      }
      .full {
        max-width: 100%;
        width: 100%;
      }
    }
  }
`;
