import styled from 'styled-components';

export const CerereProdus = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
  & > div {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    background: white;
    border-radius: 6px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    @media (max-width: 768px) {
      border-top-width: 16px;
    }
    .header {
      padding: 8px 10px 5px 10px;
      border-bottom: 1px solid #e5e5e5;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        margin: 0;
        font-size: 24px;
        font-weight: 400;
      }
      svg {
        cursor: pointer;
      }
    }
    .contents {
      margin-top: 0;
      padding: 0 15px 20px;
      .subcontent {
        margin-top: 10px;
        margin-bottom: 16px;
        font-size: 14px;
        @media (max-width: 768px) {
          display: none;
        }
        span {
          @media (max-width: 768px) {
            display: none;
          }
        }
      }
    }
    .first_row {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      gap: 24px;
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 16px;
      }
      & > div {
        max-width: 50%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 768px) {
          max-width: 100%;
        }
        h3 {
          color: blue;
          /* Create a linear gradient from black to blue */
          background: linear-gradient(to right, #212121, #1bb9a4);
          /* Clip the background so it is only behind the text */
          -webkit-background-clip: text;
          background-clip: text;
          /* Make the text color transparent, so the gradient is visible */
          -webkit-text-fill-color: transparent;
          text-fill-color: transparent;
          font-size: 20px;
          position: relative;
          width: fit-content;
          line-height: 30px;
          @media (max-width: 768px) {
            font-size: 20px !important;
          }
          @media (max-width: 390px) {
            font-size: 20px !important;
          }
        }
        .pret {
          font-size: 22px;
          color: #3e3935;
          line-height: 24px;
          margin-top: -5px;
          margin-bottom: 2px;
          @media (max-width: 768px) {
            margin-bottom: 5px;
            margin-top: 0;
          }
          strong {
            font-weight: 600;
          }
        }
        h4 {
          font-weight: 500;
          margin-top: 0px;
          @media (max-width: 768px) {
            font-weight: 300;
            margin-bottom: 4px;
          }
        }
        .options {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-top: 2px;
          p {
            &:first-child {
              background: #1bb9a3;
              color: #fff;
              padding: 4px 8px;
              border-radius: 8px;
              @media (max-width: 440px) {
                font-size: 12px;
              }
              @media (max-width: 379px) {
                padding: 4px 6px;
                font-size: 11px;
              }
            }
            &:last-child {
              background: #cf5b4c;
              color: #3e3935;
              padding: 4px 8px;
              border-radius: 8px;
              color: #fff;
              @media (max-width: 440px) {
                font-size: 12px;
              }
              @media (max-width: 379px) {
                padding: 4px 6px;
                font-size: 11px;
              }
            }
          }
        }
        img {
          max-width: 100%;
          width: 100%;
          height: 130px;
          object-fit: cover;
          object-position: center;
          border-radius: 6px;
          margin-bottom: 4px;
          @media (max-width: 450px) {
            height: 110px;
          }
        }
      }
    }

    .form {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      gap: 18px 8px;
      height: auto;
      @media (max-width: 450px) {
        gap: 10px 8px;
      }
      .red_border {
        p {
          color: #cf5b4c !important;
        }
        textarea,
        input {
          border-color: #cf5b4c !important;
        }
      }
      .input {
        max-width: 100%;
        width: 100%;
        p {
          color: #212121;
          font-size: 16px;
          font-weight: 600;
        }
        input,
        textarea {
          width: 100%;
          border: none;
          border-bottom: 1px solid #ccc;
          transition: 0.3s ease-in-out;
          padding-bottom: 8px;
          padding-top: 12px;
          resize: none;
          font-family: inherit;
          &:hover,
          &:active,
          &:focus-visible,
          &:focus-within {
            border: none;
            outline: none;
            border-bottom: 1px solid #000;
          }
        }
      }
      button {
        margin-left: auto;
        text-align: center;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border: none;
        padding: 8px 16px;
        color: #fff;
        font-size: 16px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        background: #1bb9a4;
        transition: 0.3s ease-in-out;
        cursor: pointer;
        &:hover {
          background: #169482;
        }
      }
    }
  }
`;
