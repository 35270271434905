const Cerere = ({ ...props }) => {
  return (
    <svg
      viewBox='0 0 100 100'
      xmlns='http://www.w3.org/2000/svg'
      fill='#1bb9a4'
      stroke='#1bb9a4'
      {...props}>
      <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        stroke-linecap='round'
        stroke-linejoin='round'></g>
      <g id='SVGRepo_iconCarrier'>
        {' '}
        <rect
          fill='none'
          height='6'
          rx='2'
          width='34'
          x='33'
          y='50'></rect>{' '}
        <rect fill='none' height='6' rx='2' width='30' x='33' y='62'></rect>{' '}
        <g>
          {' '}
          <path d='m63 36h10.6a1.37 1.37 0 0 0 1.4-1.4 1.28 1.28 0 0 0 -.4-1l-13.2-13.2a1.28 1.28 0 0 0 -1-.4 1.37 1.37 0 0 0 -1.4 1.4v10.6a4 4 0 0 0 4 4z'></path>{' '}
          <path d='m73 42h-14a6 6 0 0 1 -6-6v-14a2 2 0 0 0 -2-2h-20a6 6 0 0 0 -6 6v48a6 6 0 0 0 6 6h38a6 6 0 0 0 6-6v-30a2 2 0 0 0 -2-2zm-40-2a2 2 0 0 1 2-2h8.18a2 2 0 0 1 2 2v2a2 2 0 0 1 -2 2h-8.18a1.94 1.94 0 0 1 -2-2zm30 26a2 2 0 0 1 -2 2h-26a2 2 0 0 1 -2-2v-2a2 2 0 0 1 2-2h26a2 2 0 0 1 2 2zm4-12a2 2 0 0 1 -2 2h-30a2 2 0 0 1 -2-2v-2a2 2 0 0 1 2-2h30a2 2 0 0 1 2 2z'></path>{' '}
        </g>{' '}
      </g>
    </svg>
  );
};

export default Cerere;
