import Header from './components/Header';
import Footer from './components/Footer';
import ProduseHero from './components/ProduseHero';

const ProdusePage = () => {
  return (
    <>
      <Header />
      <ProduseHero />
      <Footer />
    </>
  );
};

export default ProdusePage;
